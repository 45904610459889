<template>
  <div v-if="isAuthenticated">
    <div class="grid-x grid-padding-x">
      <div class="cell callout primary"><h3><i class="fi-widget"></i>&nbsp;&nbsp;&nbsp;Verwaltung</h3>
      </div>
    </div>

    <div class="grid-x grid-padding-x grid-padding-y">
      <div class="cell">
        <md-card>
          <md-tabs id="organization-tabs-id" md-dynamic-height @md-changed="onTabChanged">
            <md-tab id="tab-master-data-id" md-label="Stammdaten">
            </md-tab>
            <md-tab id="tab-contract-id" md-label="Vertrag">
            </md-tab>
            <md-tab id="tab-settlements-id" md-label="Rechnungen">
            </md-tab>
            <md-tab id="tab-tax-certificates-id" md-label="Steuerbescheinigungen">
            </md-tab>
          </md-tabs>
        </md-card>
      </div>
    </div>
  </div>
</template>


<script>
  import Spinner from 'vue-simple-spinner';
  import jQuery from "jquery";

  export default {
    name: 'ParentsAdministration',
    components: {
        'vue-simple-spinner': Spinner
    },

    mounted() {
      setTimeout(() => jQuery(document).foundation(), 1000);
    },

    data() {
      return {
        sending: false,
        success: false,
        successMsg: '',
        error: false,
        errorMsg: '',
      }
    },

    methods: {

      onTabChanged(tabId) {
        console.log('id ' + tabId);
      },
    },

    computed: {
      isAuthenticated() {
        return this.$store.getters.authenticated
      },

      isParent() {
        return this.$store.getters.parent
      },

      isBeta() {
        return this.$store.getters.beta
      },

      labels() {
        return this.$store.getters.labels
      },
    }
  }
</script>

<style lang="scss">
  @import 'src/assets/scss/app-parents';
</style>
